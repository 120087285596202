import chroma from "chroma-js";
import tinycolor from "tinycolor2";
import { convertToHsl } from "./convertColors";

// Función para normalizar ángulos (asegurando valores entre 0 y 360)
const mod360 = angle => ((angle % 360) + 360) % 360;

// Función auxiliar para rotar el matiz de un color dado
const rotateHue = (color, degree) => {
  const hsl = chroma(color).hsl();
  hsl[0] = mod360(hsl[0] + degree);
  return chroma.hsl(...hsl).hex();
};

// Genera una escala de tonos (shades) para un color base
function generateShadesOfColors(hex, numColors, brighten = 0, darken = 0) {
  const base = chroma(hex);
  const start = base.darken(darken).hex();
  const end = base.brighten(brighten).hex();
  return chroma.scale([start, hex, end]).mode("lab").colors(numColors);
}

// Esquema monocromático
export function monochromaticScheme(hex, numColors = 5) {
  return generateShadesOfColors(hex, numColors, 2.3, 2.2);
}

// Esquema complementario
export function complementaryScheme(hex) {
  const compHex = rotateHue(hex, 180);
  return [
    ...generateShadesOfColors(hex, 3, 0.5, 0.3),
    ...generateShadesOfColors(compHex, 2, 0.5, 0.2),
  ];
}

// Esquema de complementos divididos (Split Complement)
export function splitComplementScheme(hex) {
  const splitColors = tinycolor(hex)
    .splitcomplement()
    .map(t => t.toHexString());
  return splitColors;
}

// Esquema análogo
export function analogousScheme(hex, with3Colors = false) {
  const color1 = rotateHue(hex, 30);
  const color2 = rotateHue(hex, -30);
  if (with3Colors) {
    return [chroma(hex).hex(), color1, color2];
  }
  return [
    ...generateShadesOfColors(hex, 2, 0.2, 0.2),
    ...generateShadesOfColors(color1, 2, 0.2, 0.2),
    ...generateShadesOfColors(color2, 1, 0.2, 0.2),
  ];
}

// Esquema triádico
export function triadicScheme(hex, with3Colors = false) {
  const color1 = rotateHue(hex, 120);
  const color2 = rotateHue(hex, -120);
  if (with3Colors) {
    return [chroma(hex).hex(), color1, color2];
  }
  return [
    ...generateShadesOfColors(hex, 2, 0.3, 0.2),
    ...generateShadesOfColors(color1, 2, 0.3, 0.2),
    ...generateShadesOfColors(color2, 1, 0.2, 0.2),
  ];
}

// Esquema tetrádico (dos pares complementarios)
export function tetradicScheme(hex, with4Colors = false) {
  const color1 = rotateHue(hex, 90);
  const color2 = rotateHue(hex, 180);
  const color3 = rotateHue(hex, 270);
  if (with4Colors) {
    return [chroma(hex).hex(), color1, color2, color3];
  }
  return [
    ...generateShadesOfColors(hex, 2, 0.3, 0.2),
    ...generateShadesOfColors(color1, 2, 0.3, 0.2),
    ...generateShadesOfColors(color2, 1, 0.2, 0.2),
    ...generateShadesOfColors(color3, 1, 0.2, 0.2),
  ];
}

// Esquema cuadrado (colores a 90°)
// export function squareScheme(hex, with4Colors = false) {
//   const color1 = rotateHue(hex, 90);
//   const color2 = rotateHue(hex, 180);
//   const color3 = rotateHue(hex, 270);
//   if (with4Colors) {
//     return [chroma(hex).hex(), color1, color2, color3];
//   }
//   return [
//     ...generateShadesOfColors(hex, 2, 0.3, 0.2),
//     ...generateShadesOfColors(color1, 2, 0.3, 0.2),
//     ...generateShadesOfColors(color2, 1, 0.2, 0.2),
//     ...generateShadesOfColors(color3, 1, 0.2, 0.2),
//   ];
// }

// Función que selecciona el esquema solicitado
export function generateColorsSChemes(hexColor, scheme) {
  const schemes = {
    monochromatic: monochromaticScheme(hexColor),
    complementary: complementaryScheme(hexColor),
    analogous: analogousScheme(hexColor),
    triadic: triadicScheme(hexColor),
    splitComplement: splitComplementScheme(hexColor),
    tetradic: tetradicScheme(hexColor),
    // square: squareScheme(hexColor),
  };
  return schemes[scheme];
}
